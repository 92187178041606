<template>
    <div class="content">
      <notice label="detail_page_tips"/>
      <!-- <div class="contentHeader flex_jcsb_ac">
        <div class="orderStatus flex_ac">
            <span>设置订单状态：</span>
            <div class="status flex_jc_ac active" v-for="item in 6" :key="item">已提交</div>
        </div>
      </div> -->
      <div class="fillingArea flex">
          <div class="area">
            <el-form label-position="top" :model="userForm">
              <el-form-item label="用户名" prop="username">
                <div class="input"><el-input v-model="userForm.username" disabled></el-input></div>
              </el-form-item>
              <el-form-item label="手机号" prop="mobile">
                <div class="input"><el-input v-model="userForm.mobile" disabled></el-input></div>
              </el-form-item>
              <el-form-item label="联系人邮箱" prop="email">
                <div class="input"><el-input v-model="userForm.email" disabled></el-input></div>
              </el-form-item>
            </el-form>
          </div>
          <div class="area">
            <div class="areaTitle">订单编号：{{ orderDetail.order_num }}</div>
            <div class="areaTitle">您选择了{{ orderDetail.setmeal_name }}：<span>￥{{ orderDetail.price }}</span></div>
            <div class="desc">
                <div class="descTitle">套餐说明</div>
                <div class="descContent">{{ orderDetail.describe }}</div>
            </div>
            <div class="steps flex">
                <div class="step flex_ac" v-for="(item, index) in orderDetail.rate" :key="index" :class="{ active: item.active }" >
                    <div class="pencil"></div>
                    <div class="circle"></div>
                    <span>{{ item.text }}</span>
                </div>
            </div>
          </div>
        </div>
        <!-- <div class="descriptionArea">
          <div class="desTitle">员工备注</div>
          <div class="textArea">
            <el-input type="textarea" resize="none"></el-input>
          </div>
        </div> -->
        <!-- <div class="descriptionArea" style="margin-top: 20px;">
          <div class="desTitle">客户备注</div>
          <div class="textArea">
            <el-input type="textarea" resize="none"></el-input>
          </div>
        </div> -->
        <div class="buttonGroup" v-if="orderDetail.status != 5 && orderDetail.status != 6">
            <el-button class="saveBtn" type="primary" @click='orderCancel'>取消订单</el-button>
            <el-button class="saveBtn" type="primary" :disabled="orderDetail.paytime !== 0" v-if="orderDetail.status === '2' || orderDetail.status === '0'" @click="pay">去支付</el-button>
        </div>
    </div>
</template>

<script>
import notice from '../components/Notice.vue'

export default {
  name: 'orderDetail',
  components: {
    notice
  },
  data() {
    return {
      userForm: {
        mobile: '',
        username: '',
        email: ''
      },
      order_num: '',
      orderDetail: {}
    }
  },
  created() {
    this.order_num = this.$route.query.order_num
    this.getOrderDetail()
  },
  methods: {
    orderCancel() {
      this.$router.push({
        path: '/home/detail',
        query: { order_num: this.order_num }
      })
    },
    pay() {
      this.$router.replace({
        path: '/home/pay',
        query: { order_num: this.order_num }
      })
    },
    getOrderDetail() {
      this.$api.getOrderDetail({ order_num: this.order_num }).then(res => {
        this.orderDetail = res.info
        Object.keys(this.userForm).forEach(item => {
          this.userForm[item] = res.info[item]
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.content {
    width: 100%;
    box-sizing: border-box;
    padding: 0 20px;
    border: 1px solid #e3e3e3;
    padding-bottom: 20px;
    .fillingArea {
      margin-top: 10px;
      width: 100%;
      .area {
        flex: 1;
        .input {
          width: 450px;
        }
        .areaTitle {
          font-size: 14px;
          line-height: 40px;
          padding-bottom: 10px;
          span {
            font-weight: bold;
            color: #409EFF;
          }
        }
        .desc {
          .descTitle {
            font-size: 18px;
          }
          .descContent {
            margin-top: 10px;
            height: 165px;
            overflow-y: auto;
            line-height: 20px;
          }
        }
        .button {
          margin: 22px 0;
          .buttonClass {
            width: 200px;
            height: 48px;
            &:first-child {
              width: 330px;
            }
          }
        }
      }
    }
  }
  .steps {
    margin-top: 30px;
    .pencil {
        width: 70px;
        height: 8px;
        border-radius: 4px;
        background-color: #dcdcdc;
        margin-right: -5px;
    }
    .circle {
        width: 18px;
        height: 18px;
        background-color: #dcdcdc;
        border-radius: 50%;
    }
    span {
        font-size: 14px;
        margin-left: 4px;
        color: #dcdcdc;
    }
    .step {
        margin-right: 10px;
        &.active {
           span {
            color: #333;
           }
           .pencil, .circle {
              background-color: #abd155;
           }
        }
    }
}
.contentHeader {
        margin-top: 10px;
        .elButton {
            width: 180px;
            height: 42px;
        }
        .status {
            width: 84px;
            height: 25px;
            box-sizing: border-box;
            border: 1px solid #333;
            margin-left: 10px;
            &.active {
                border-color: #abd155;
                color: #abd155;
            }
        }
        .search {
            width: 80px;
            height: 38px;
            margin-left: 10px;
        }
    }
    .descriptionArea {
    &:last-child {
        margin-top: 20px;
    }
    .desTitle {
        font-weight: bold;
    }
    .textArea {
        margin-top: 16px;
    }
  }
  .buttonGroup {
    text-align: center;
    margin-top: 20px;
    .saveBtn {
      width: 200px;
      height: 50px;
      &:last-child {
        margin-left: 20px;
      }
    }
  }
</style>
